import Pusher from "pusher-js";

const pusher = (token = null) => new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
	cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
	enabledTransports: ['ws', 'wss'],
	authEndpoint: `${process.env.REACT_APP_BACKEND_API}/broadcasting/auth`,
	auth: {
		headers: {
			"Authorization": token
		}
	}
});

export default pusher
