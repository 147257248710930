import {createApi} from "@reduxjs/toolkit/query/react";
import pusher from "../pusher-common";
import http from "../http-common";

const axiosInstance = http

const axiosBaseQuery = () => async (requestOpts) => {
	try {
		const result = await axiosInstance({
			url: requestOpts.url ?? requestOpts,
			method: requestOpts.method ?? 'GET',
			data: requestOpts.body, ...requestOpts
		});
		return {data: result.data};
	} catch (axiosError) {
		let err = axiosError;
		return {
			error: {
				status: err.response?.status,
				data: err.response?.data || err.message
			}
		};
	}
};

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['Products', 'Recent Orders', 'Overview', 'Active Orders'],
	endpoints: build => ({
		getOverview: build.query({
			query: (token) => ({
				url: '/api/overview',
				method: "GET",
				headers: {
					Authorization: token
				}
			}),
			providesTags: ['Overview']
		}),
		getAllProducts: build.query({
			query: (token) => ({
				url: '/api/products/all',
				method: "GET",
				headers: {
					Authorization: token
				}
			}),
			async onCacheEntryAdded(
				arg, {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
			) {
				const ws = pusher().subscribe('products')

				await cacheDataLoaded

				ws.bind('stock-events', (data) => {
					data.forEach((datum) => {
						console.log(datum)
						switch (datum.type) {
							case 'stock':
								updateCachedData(draft => {
									draft.push(datum.data)
								})
								break
							default:
								break
						}
					})
				})
				await cacheEntryRemoved
			}
		}),
		getRecentOrders: build.query({
			query: (token) => ({
				url: '/api/orders',
				method: "GET",
				headers: {
					Authorization: token
				}
			}),
			providesTags: ['Recent Orders']
		}),
		getActiveOrders: build.query({
			query: (token) => ({
				url: '/api/orders/current',
				method: "GET",
				headers: {
					Authorization: token
				}
			}),
			providesTags: ['Active Orders']
		}),
		getAllBanners: build.query({
			query: (token) => ({
				url: '/api/banners/all',
				method: "GET",
				headers: {
					Authorization: token
				}
			}),
			providesTags: ['banners']
		}),
		activateBanner: build.mutation({
			query: ({id, token}) => ({
				url: '/api/banner/activate',
				method: "POST",
				body: {
					id: id
				},
				headers: {
					Authorization: token
				}
			}),
			invalidatesTags: ['banners']
		}),
		processOrder: build.mutation({
			query: ({order, token}) => ({
				url: '/api/order/process',
				method: "POST",
				body: order,
				headers: {
					Authorization: token
				}
			}),
			invalidatesTags: ['Active Orders', 'Recent Orders', 'Overview']
		}),
		cancelOrder: build.mutation({
			query: ({id, token}) => ({
				url: `/api/order/cancel/${id}`,
				method: "POST",
				headers: {
					Authorization: token
				}
			}),
			invalidatesTags: ['Active Orders', 'Recent Orders', 'Overview']
		}),
		updateProduct: build.mutation({
			query: ({row, token}) => {
				const {sku, ...data} = row
				return ({
					url: `/api/product`,
					method: "PATCH",
					withCredentials: true,
					body: {
						'id': sku,
						'description': data.description,
						'price': data.price,
						'units': data.units
					},
					headers: {
						Authorization: token
					}
				})
			},
			invalidatesTags: ['Products']
		})
	})
})

export const {
	useGetOverviewQuery,
	useGetAllProductsQuery,
	useGetRecentOrdersQuery,
	useGetActiveOrdersQuery,
	useGetAllBannersQuery,
	useActivateBannerMutation,
	useProcessOrderMutation,
	useCancelOrderMutation,
	useUpdateProductMutation
} = apiSlice