/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from 'react-auth-kit'
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import store from "./store";

ReactDOM.render(
    <AuthProvider
        authType={"localstorage"}
        authName={"_auth"}
        cookieDomain={process.env.REACT_APP_APP_DOMAIN}
        cookieSecure={process.env.NODE_ENV === 'production' && window.location.protocol === "https:"}
    >
        <Provider store={store}>
            <BrowserRouter>
                <MaterialUIControllerProvider>
                    <App />
                </MaterialUIControllerProvider>
            </BrowserRouter>
        </Provider>
    </AuthProvider>,
  document.getElementById("root")
);
