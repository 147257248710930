import loading from "../../assets/animations/loading.json";
import {useLottie} from "lottie-react";
import {Box} from "@mui/material";

const View = () => {
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: loading,
    }

    const {View} = useLottie(animationOptions, {height: '100vh'})

    return View
}

export const Page = () => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <View />
        </Box>
    )
}