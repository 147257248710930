import {useState, useEffect} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {useMaterialUIController, setMiniSidenav} from "context";

// Images
import brandWhite from "assets/images/nyamnyam.png";
import brandDark from "assets/images/darknyamnyam.png";
import {useIsAuthenticated} from "react-auth-kit";
import {AuthLoader} from "./components/MDLoading";
import {SnackbarProvider} from "notistack";
import ModalProvider from "mui-modal-provider";

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();
    const isAuthenticated = useIsAuthenticated()

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <SnackbarProvider>
                <ModalProvider>
                    <CssBaseline/>
                    {!isAuthenticated() && pathname !== '/authentication/sign-in' && <AuthLoader/>}
                    {isAuthenticated() && layout === "dashboard" && (
                        <>
                            <Sidenav
                                color={sidenavColor}
                                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                brandName=" Admin Panel"
                                routes={routes}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                        </>
                    )}
                    <Routes>
                        {getRoutes(routes)}
                        <Route path="*" element={<Navigate to="/authentication/sign-in"/>}/>
                    </Routes>
                </ModalProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
