import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "./slices/api";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV === 'development'
})

export default store