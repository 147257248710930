import loadable from '@loadable/component'
import { RequireAuth } from 'react-auth-kit'

// @mui icons
import Icon from "@mui/material/Icon";

// Page Loading Animation
import {PageLoader} from "./components/MDLoading";

const Dashboard = loadable(() => import("layouts/dashboard"), {
    fallback: <PageLoader/>,
    ssr: false
})

const Orders = loadable(() => import("./layouts/Orders"), {
    fallback: <PageLoader/>,
    ssr: false
})

const ProductsPage = loadable(() => import("./layouts/products"), {
    fallback: <PageLoader/>,
    ssr: false
})

const EditorPage = loadable(() => import("./layouts/EditContents"), {
    fallback: <PageLoader/>,
    ssr: false
})

const Customers = loadable(() => import("./layouts/Customers"), {
    fallback: <PageLoader/>,
    ssr: false
})

const SignIn = loadable(() => import("./layouts/authentication/sign-in"), {
    fallback: <PageLoader/>,
    ssr: false
})

const routes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: (<RequireAuth loginPath={'/authentication/sign-in'}><Dashboard/></RequireAuth>),
        roles: ['admin', 'manager']
    },
    {
        type: "collapse",
        name: "Orders",
        key: "orders",
        icon: <Icon fontSize="small">list</Icon>,
        route: "/orders",
        component: (<RequireAuth loginPath={'/authentication/sign-in'}><Orders/></RequireAuth>),
        roles: ['admin', 'manager', 'customer-attendant']
    },
    {
        type: "collapse",
        name: "Products",
        key: "products",
        icon: <Icon fontSize="small">bookmark</Icon>,
        route: "/products",
        component: (<RequireAuth loginPath={'/authentication/sign-in'}><ProductsPage/></RequireAuth>),
        roles: ['admin', 'manager', 'product-manager']
    },

    {
        type: "collapse",
        name: "Edit Content",
        key: "edit",
        icon: <Icon fontSize="small">edit</Icon>,
        route: "/edit",
        component: (<RequireAuth loginPath={'/authentication/sign-in'}><EditorPage/></RequireAuth>),
        roles: ['admin', 'manager', 'content-editor']
    },

    {
        type: "collapse",
        name: "Customers",
        key: "customers",
        icon: <Icon fontSize="small">group</Icon>,
        route: "/customers",
        component: (<RequireAuth loginPath={'/authentication/sign-in'}><Customers/></RequireAuth>),
        roles: ['admin', 'manager']
    },

    {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/authentication/sign-in",
        component: <SignIn/>,
        roles: ['*']
    },
];

export default routes;
